import { Box, Center, Collapse, Group, Loader, Paper, Stack, Switch, Title, useMantineTheme } from "@mantine/core";
import { useMediaQuery, useToggle } from "@mantine/hooks";
import { Highscore, useGetHighscoreForExercise } from "../../api/getForExercise";
import RunRow from "../RunRow/RunRow";

const MAX_ITEMS = 5;

export interface ExerciseData {
    name: string;
    organization: string;
    school: string;
    runtime: number;
    timestamp: string;
}

interface ExerciseToplistProps {
    publicExerciseUuid: string;
    name: string;
}

function ExerciseToplist(props: ExerciseToplistProps) {

    const [showAll, toggleShowAll] = useToggle(false, [true, false]);
    const desktop = useMediaQuery('(min-width: 800px)');

    const dataQuery = useGetHighscoreForExercise(props.publicExerciseUuid);
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
    const titleSize = mobile ? 3 : 3;

    const highscoreList = dataQuery.data?.HighScore ?? [];
    const topFive = highscoreList.slice(0, MAX_ITEMS);
    const rest = highscoreList.length > MAX_ITEMS ? highscoreList.slice(MAX_ITEMS) : undefined;

    const switchLabel = `Visa hela listan (${highscoreList.length} st)`;

    if (dataQuery.isLoading || dataQuery.isIdle)
        return (
            <Loader />
        );

    if (dataQuery.isError)
        return (<>Error</>);

    if (highscoreList.length === 0)
        return (
            <Group spacing={63} align="flex-start">
                <Box sx={{ flexGrow: 1 }}>
                    <Stack spacing="xl">
                        <Title order={titleSize} sx={{ color: "white" }}>{props.name}</Title>
                        <Paper
                            p="xl"
                            radius="md"
                        >
                            <Center>
                                Inga körningar än
                            </Center>
                        </Paper>
                    </Stack>
                </Box>
            </Group>
        )

    return (
        <Group spacing={63} align="flex-start">
            <Box sx={{ flexGrow: 1 }}>
                <Stack spacing="xl">
                    <Group position="apart">
                        <Title order={titleSize} sx={{ color: "white" }}>{props.name}</Title>
                        {(desktop && !!rest) ? (
                            <Switch
                                label={switchLabel}
                                checked={showAll}
                                onChange={() => toggleShowAll()}
                            />
                        ) : null}
                    </Group>
                    <Stack spacing="xs">
                        {desktop ? (
                            <RunRow number={"Nr"} name="Namn" runtime={"Tid"} timestamp="Datum" />
                        ) : null}
                        {topFive.map((run: Highscore, index) =>
                            <RunRow
                                key={index}
                                number={index + 1}
                                name={run.Name}
                                runtime={run.Runtime}
                                timestamp={run.Date}
                            />
                        )}
                        {rest ? (
                            <Collapse in={showAll}>
                                <Stack spacing="xs">
                                    {rest.map((run: Highscore, index) =>
                                        <RunRow
                                            key={index}
                                            number={index + 1 + MAX_ITEMS}
                                            name={run.Name}
                                            runtime={run.Runtime}
                                            timestamp={run.Date}
                                        />
                                    )}
                                </Stack>
                            </Collapse>
                        ) : null}

                        {(!desktop && !!rest) ? (
                            <Box mt="lg">
                                <Switch
                                    label={switchLabel}
                                    checked={showAll}
                                    onChange={() => toggleShowAll()}
                                />
                            </Box>
                        ) : null}

                    </Stack>
                </Stack>
            </Box>
        </Group>
    )
}

export default ExerciseToplist;