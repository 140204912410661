import { Image } from "@mantine/core";
import bg from '../../resources/images/background.png';

function MapBackground() {
    return (
        <Image
            src={bg}
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                opacity: .7,
                mixBlendMode: "multiply",
                zIndex: -1
            }}
        />
    )
}

export default MapBackground;