import { Grid, Group, Loader } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useAtom } from "jotai";
import { HighscoreResponse, useGetHighscoreCourses } from "../../api/getHighscoreCourses";
import { selectedCourseAtom } from "../../atoms";
import CourseCard from "../CourseCard/CourseCard";

function CourseCards() {

    const coursesQuery = useGetHighscoreCourses();
    const [selectedCourseUuid, setSelectedCourseUuid] = useAtom(selectedCourseAtom);

    const desktop = useMediaQuery('(min-width: 580px)');

    if (coursesQuery.isLoading || coursesQuery.isIdle)
        return (
            <Loader />
        );

    if (coursesQuery.isError)
        return (
            <>Error</>
        );

    if (desktop)
        return (
            <Group>
                {coursesQuery.data.list.map((course: HighscoreResponse) => (
                    <CourseCard
                        key={course.PublicUUID}
                        title={course.Name}
                        publicUuid={course.PublicUUID}
                        imageUuid={course.ImageUUID}
                        selected={course.PublicUUID === selectedCourseUuid}
                        onClick={() => setSelectedCourseUuid(course.PublicUUID)}
                    />

            ))}
            </Group>
        )
    return (
        <Grid columns={12}>
            {coursesQuery.data.list.map((course: HighscoreResponse) => (
                <Grid.Col
                    key={course.PublicUUID}
                    span={6}
                >
                    <CourseCard
                        title={course.Name}
                        publicUuid={course.PublicUUID}
                        imageUuid={course.ImageUUID}
                        selected={course.PublicUUID === selectedCourseUuid}
                        onClick={() => setSelectedCourseUuid(course.PublicUUID)}
                    />
                </Grid.Col>
            ))}
        </Grid>
    )
}

export default CourseCards;