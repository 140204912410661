export function formatRuntime(secondsWithDecimals: number) {
  const m = Math.floor((secondsWithDecimals % 3600) / 60);
  const s = Math.floor((secondsWithDecimals % 3600) % 60);
  const ms = (secondsWithDecimals - Math.floor(secondsWithDecimals))
    .toString()
    .slice(2, 4);

  const mDisplay = m < 10 ? "0" + m : m;
  const sDisplay = s < 10 ? "0" + s : s;
  const msDisplay = ms;

  return `${mDisplay}:${sDisplay}.${msDisplay}`;
}
