import { Box, Stack, Text, } from "@mantine/core";

function Info() {
    return (
        <Stack spacing={20} align="center">
            <Box sx={{ maxWidth: 600 }}>
                <Text
                    weight={600}
                    sx={(theme) => ({
                        color: theme.colors.bluegray[0]
                    })}
                    align="center"
                >
                    Resultatlista för Skillsters tävling i montern på Yrkes-SM 2022.
                </Text>
            </Box>
        </Stack>
    )
}

export default Info;