import { useQuery } from "react-query";

export interface Highscore {
  ID: number;
  Name: string;
  Date: string;
  Runtime: number;
  SchoolName: string;
  OrganizationName: string;
  PublicExerciseUUID: string;
}

export interface HighscoreForExerciseResponse {
  StartDate: string;
  EndDate: string;
  HighScore: Highscore[];
}

export function useGetHighscoreForExercise(publicExerciseUuid: string) {
  return useQuery<HighscoreForExerciseResponse, Error>(
    ["get-highscore-for-exercise", publicExerciseUuid],
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/highscore/get-for-exercise?uuid=${publicExerciseUuid}`,
        {
          method: "get",
        }
      );
      const json = await response.json();
      return json.Data;
    }
  );
}
