import { MantineThemeOverride } from "@mantine/core";

export const highscoreTheme: MantineThemeOverride = {
  colorScheme: "dark",
  headings: {
    fontFamily: "Rajdhani",
    sizes: {
      h1: { fontSize: 34, lineHeight: "40px" },
      h2: { fontSize: 26, lineHeight: "31px" },
      h3: { fontSize: 22, lineHeight: "26px" },
      h4: { fontSize: 18, lineHeight: "21px" },
      h5: { fontSize: 16, lineHeight: "19px" },
      h6: { fontSize: 14, lineHeight: "17px" },
    },
  },
  fontFamily: "Open Sans",
  fontSizes: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 18,
  },
  colors: {
    blue: [
      "#DEE6F7",
      "#C2D1F5",
      "#9CB7F1",
      "#769CED",
      "#5991F2",
      "#3B86F7",
      "#3670D6",
      "#3059B4",
      "#2C4988",
      "#28385C",
    ],
    green: [
      "#D2F1E5",
      "#A2EFD9",
      "#72E7C5",
      "#42DFB0",
      "#21DBB0",
      "#00D6AF",
      "#08C79A",
      "#0FB885",
      "#128F6A",
      "#14654E",
    ],
    pink: [
      "#FFE0E4",
      "#FAE0E0",
      "#F7D2D2",
      "#F3C3C3",
      "#F9A2A9",
      "#FF808F",
      "#F16A7F",
      "#E3546E",
      "#A83A4C",
      "#6C2029",
    ],
    yellow: [
      "#F6F4C2",
      "#F6EEA6",
      "#F4EB99",
      "#F1E78B",
      "#F6E761",
      "#FAE636",
      "#DBC81C",
      "#BBA902",
      "#9E8A01",
      "#806B00",
    ],
    grape: [
      "#F1E8FC",
      "#EBE1F7",
      "#D5C6F5",
      "#BEABF3",
      "#BB96F8",
      "#B781FC",
      "#965DDF",
      "#7538C2",
      "#5C2D98",
      "#43216D",
    ],
    bluegray: [
      "#EFF3F8",
      "#E7EDF5",
      "#E1E7F1",
      "#DAE1EC",
      "#A6B0C2",
      "#717F98",
      "#545F73",
      "#373E4D",
      "#303646",
      "#282E3F",
    ],
    gray: [
      "#EEEEEE",
      "#D6D6D6",
      "#C5C5C5",
      "#B4B4B4",
      "#969696",
      "#777777",
      "#676767",
      "#575757",
      "#454545",
      "#333333",
    ],
    orange: [
      "#F7EFDE",
      "#F5E4C2",
      "#F1D59C",
      "#EDC576",
      "#F2BF59",
      "#F7B83B",
      "#D6A136",
      "#B48830",
      "#88692C",
      "#5C4B28",
    ],
    dark: [
      "#797981",
      "#595964",
      "#53535E",
      "#474750",
      "#3C3C46",
      "#34353F",
      "#2B2C35",
      "#23242C",
      "#1F2027",
      "#191A20",
    ],
  },
};
