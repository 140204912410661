import { Image, Paper, Title, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { trimCourseName } from "../../helpers/trimCourseName";

interface CourseCardProps {
    publicUuid: string;
    imageUuid: string;
    selected?: boolean;
    title: string;
    onClick: () => void;
}

function CourseCard(props: CourseCardProps) {

    const imageUrl = `https://res.cloudinary.com/dy7i8hekz/image/upload/c_scale,w_294,h_165,c_fill,g_north/v1650954774/Event/Vehicles/${props.imageUuid}.png`;
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

    return (
        <Paper
            sx={(theme) => ({
                position: "relative",
                backgroundColor: props.selected ? theme.colors.blue[9] : theme.colors.dark[6],
                border: props.selected ? `2px solid ${theme.colors.blue[3]}` : "2px solid transparent",
                maxWidth: 266,
                cursor: "pointer",
                ':hover': {
                    backgroundColor: props.selected ? theme.colors.blue[9] : theme.colors.dark[5],
                    '.vehicle': {
                        transform: "scale(1.025)",
                        filter: "brightness(1.2)"
                    }
                }
            })}
            p="md"
            pb="xl"
            radius="md"
            onClick={props.onClick}
        >
            <Title
                order={mobile ? 5 : 4}
                sx={(theme) => ({
                    color: theme.white,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "90%",
                })}
            >
                {trimCourseName(props.title)}
            </Title>
            {/* <Title
                order={5}
                sx={(theme) => ({
                    color: theme.colors.dark[0]
                })}
            >
                Anläggning
            </Title> */}

                <Image
                    src={imageUrl}
                    alt="Vehicle"
                    className="vehicle"
                    mt={20}
                    sx={{
                        filter: 'brightness(1.1)',
                        transition: "transform 200ms ease-in-out, filter 200ms ease-in-out",
                    }}
                />


        </Paper>
    )
}

export default CourseCard;