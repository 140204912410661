import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { selectedCourseAtom } from "../atoms";

export interface Exercise {
  Name: string;
  PublicUUID: string;
}

export interface HighscoreResponse {
  PublicUUID: string;
  Name: string;
  ImageUUID: string;
  Exercises: Exercise[];
}

export function useGetHighscoreCourses() {
  const [selectedCourseUuid, setSelectedCourseUuid] =
    useAtom(selectedCourseAtom);

  return useQuery<
    { list: HighscoreResponse[]; map: Record<string, HighscoreResponse> },
    Error
  >(["get-highscore-courses"], async () => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URI}/frontend/get-highscore-courses`,
      {
        method: "get",
      }
    );
    const json = await data.json();
    const list: HighscoreResponse[] = json.Data;
    const map: Record<string, HighscoreResponse> = {};
    list.forEach((item) => (map[item.PublicUUID] = item));

    if (selectedCourseUuid === "") setSelectedCourseUuid(list[0].PublicUUID);

    return { list, map };
  });
}
