import { Loader, Stack, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useAtom } from "jotai";
import { Exercise, useGetHighscoreCourses } from "../../api/getHighscoreCourses";
import { selectedCourseAtom } from "../../atoms";
import ExerciseToplist from "../ExerciseToplist/ExerciseToplist";

function CourseToplists() {

    const highscoreCoursesQuery = useGetHighscoreCourses();
    const [selectedCourseUuid] = useAtom(selectedCourseAtom);
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

    if (highscoreCoursesQuery.isLoading || highscoreCoursesQuery.isIdle)
        return (
            <Loader />
        );

    if (highscoreCoursesQuery.isError)
        return (
            <>Error</>
        );

    return (
        <Stack spacing={mobile ? 50 : 100}>
            {highscoreCoursesQuery.data.map[selectedCourseUuid]?.Exercises.map((exercise: Exercise) => (
                <ExerciseToplist
                    key={exercise.PublicUUID}
                    publicExerciseUuid={exercise.PublicUUID}
                    name={exercise.Name}
                />
            ))}
        </Stack>
    )
}

export default CourseToplists;