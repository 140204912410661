import { Anchor, Center, Group, Image, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Info from "./components/Info";
import laurels from "./resources/images/header-laurels.svg";
import logo from "./resources/images/skillster-logo.svg";

function Header() {
    const mobile = useMediaQuery('(max-width: 800px)');

    return (
        <Stack spacing={9} mt={50}>
            {mobile ? (
                <Group position="apart" mb="xl" px="sm">
                    <Anchor href="https://www.skillster.se/"><Image src={logo} alt="Skillster" width={172} /></Anchor>
                    <Image src={laurels} alt="Skillster Trophy" width={93} />
                </Group>
            ) : (
                    <Stack spacing={0}>

                        <Anchor href="https://www.skillster.se/">
                            <Image
                                src={logo}
                                alt="Skillster"
                                width={172}
                            />
                        </Anchor>
                        <Center>
                            <Image src={laurels} alt="Skillster Trophy" width={142} mb="xs" />
                        </Center>
                    </Stack>
            )}
            <Info />
        </Stack>
    )
}
export default Header;