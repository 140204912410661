import { Affix, Button, Container, Divider, Global, MantineProvider, Stack, Title, Transition, useMantineTheme } from '@mantine/core';
import { useMediaQuery, useWindowScroll } from '@mantine/hooks';
import { ArrowUpIcon } from '@radix-ui/react-icons';
import CourseCards from './compontents/CourseCards/CourseCards';
import CourseToplists from './compontents/CourseToplists/CourseToplists';
import Header from './compontents/Header/Header';
import MapBackground from './compontents/MapBackground/MapBackground';
import confetti from "canvas-confetti";
import { useEffect } from 'react';
import { highscoreTheme } from './theme/theme';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {

    const [scroll, scrollTo] = useWindowScroll();
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

    useEffect(() => {
        confetti({
            particleCount: 200,
            spread: 170,
            origin: { x: 0, y: 0.5 },
            colors: ['#DEE6F7', '#C2D1F5', '#9CB7F1', '#769CED', '#5991F2', '#3B86F7', '#3670D6', '#3059B4', '#2C4988', '#28385C']
        });
        confetti({
            particleCount: 200,
            spread: 170,
            origin: { x: 1, y: 0.5 },
            colors: ['#DEE6F7', '#C2D1F5', '#9CB7F1', '#769CED', '#5991F2', '#3B86F7', '#3670D6', '#3059B4', '#2C4988', '#28385C']
        });
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <MantineProvider
                theme={highscoreTheme}
                styles={{
                    Button: (theme) => ({
                        root: {
                            fontFamily: theme.headings.fontFamily,
                            fontSize: theme.headings.sizes.h5.fontSize,
                            fontWeight: theme.headings.fontWeight,
                            borderRadius: theme.radius.xl,
                            height: 40,
                            paddingLeft: 25,
                            paddingRight: 25,
                        },
                        filled: {
                            backgroundColor: theme.colors.dark[6],
                            color: theme.white,
                            border: `2px solid ${theme.colors.dark[3]}`,
                            ':hover': {
                                border: `2px solid ${theme.colors.blue[5]}`,
                            },
                        }
                    }),
                    SegmentedControl: (theme) => ({
                        root: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            backgroundColor: 'transparent',
                        },
                        control: {
                            ':not(:first-of-type)': {
                                border: 0
                            },
                        },
                        label: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            fontFamily: theme.headings.fontFamily,
                            fontSize: theme.headings.sizes.h5.fontSize,
                            fontWeight: theme.headings.fontWeight,
                            borderRadius: 0,
                            marginRight: 42,
                        },
                        labelActive: {
                            borderBottom: `2px solid ${theme.colors.blue[5]}`,

                        },
                        active: { backgroundColor: 'transparent' },
                    }),
                    Switch: (theme) => ({
                        input: {
                            cursor: 'pointer',
                        },
                        label: {
                            fontFamily: theme.headings.fontFamily,
                            fontSize: theme.headings.sizes.h6.fontSize,
                            fontWeight: theme.headings.fontWeight,
                            color: theme.colors.bluegray[4],
                        },
                    }),
                }}
            >
                <Global
                    styles={(theme) => ({
                        body: {
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                        },
                    })}
                />
                <Container fluid style={{ position: "relative" }} px={0} pb={200}>
                    <Container size="md" px={mobile ? 8 : undefined}>
                        <Stack spacing={40}>
                            <Header />
                            <Divider
                                label={<Title order={6}>Topplistor</Title>}
                                labelPosition="center"
                            />
                            <CourseCards />
                            <CourseToplists />
                        </Stack>
                    </Container>
                    <MapBackground />
                </Container>

                <Affix position={{ bottom: 20, right: 20 }}>
                    <Transition transition="slide-up" mounted={scroll.y > 200}>
                        {(transitionStyles) => (
                            <Button
                                leftIcon={<ArrowUpIcon />}
                                style={transitionStyles}
                                onClick={() => scrollTo({ y: 0 })}
                            >
                                Till toppen
                            </Button>
                        )}
                    </Transition>
                </Affix>
            </MantineProvider>
        </QueryClientProvider>
    );
}

export default App;
