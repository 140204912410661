import { Box, Grid, Group, Paper, Stack, Title, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { formatRuntime } from "../../helpers/formatRuntime";

interface RunRowProps {
    number: number;
    name: string;
    runtime: number;
    timestamp: string;
}

interface RunRowTitlesProps {
    number: string;
    name: string;
    runtime: string;
    timestamp: string;
}

function RunRow(props: RunRowProps | RunRowTitlesProps) {
    const isTitleRow = typeof props.number === "string";

    const mobile = useMediaQuery('(max-width: 800px)');

    if (mobile)
        return (
            <Paper
                sx={(theme) => ({
                    backgroundColor: isTitleRow ? "transparent" : theme.colors.dark[6],
                    color: isTitleRow ? theme.colors.bluegray[4] : theme.colors.bluegray[3],
                })}
                px="sm"
                py={isTitleRow ? 0 : "sm"}
            >
                <Group>
                    <Box
                        sx={(theme) => ({
                            width: 25,
                            height: 25,
                            backgroundColor: theme.colors.dark[4],
                            borderRadius: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexShrink: 0
                        })}
                    >
                        <Title order={5}>{props.number}</Title>
                    </Box>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                        <Group position="apart">
                            <Title order={4}>{props.name}</Title>
                            <Title order={4}>{formatRuntime(Number(props.runtime))}</Title>
                        </Group>
                        <Group position="apart">
                            <Text>{props.timestamp}</Text>
                        </Group>

                    </Stack>
                </Group>
            </Paper>
        )

    return (
        <Paper
            sx={(theme) => ({
                backgroundColor: isTitleRow ? "transparent" : theme.colors.dark[6],
                color: isTitleRow ? theme.colors.bluegray[4] : theme.colors.bluegray[3],
            })}
            px="sm"
            py={isTitleRow ? 0 : "xs"}
        >
            <Grid columns={13} align="center">
                <Grid.Col span={1}>
                    {isTitleRow ? (
                        <Title order={6}>{props.number}</Title>
                    ) : (
                        <Box
                            sx={(theme) => ({
                                width: 25,
                                height: 25,
                                backgroundColor: theme.colors.dark[3],
                                borderRadius: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexShrink: 0
                            })}
                        >
                            <Title order={4} sx={{ marginTop: 2 }}>{props.number}</Title>
                        </Box>
                    )}
                </Grid.Col>
                <Grid.Col span={8}><Title order={isTitleRow ? 6 : 4}>{props.name}</Title></Grid.Col>
                <Grid.Col span={2}><Title order={isTitleRow ? 6 : 4}>{isTitleRow ? props.runtime : formatRuntime(Number(props.runtime))}</Title></Grid.Col>
                <Grid.Col span={2}><Title order={isTitleRow ? 6 : 4} sx={(theme) => ({ fontWeight: isTitleRow ? undefined : 400, color: isTitleRow ? undefined : theme.colors.bluegray[4] })}>{props.timestamp}</Title></Grid.Col>
            </Grid>
        </Paper>
    )
}

export default RunRow;